var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as dayjs from "dayjs";
import * as React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
import { PromiseQueue } from "../utils/PromiseUtils";
import styled from "styled-components";
import insideSvg from "../../assets/svg/dinner_dining.svg";
import deliverSvg from "../../assets/svg/electric_moped.svg";
import takeoutSvg from "../../assets/svg/takeout_dining.svg";
import { Button } from "../common/Button";
import { OrderStatus } from "../common/type";
import { Box } from "../component/common/FlexItem";
import { useJsonRPC } from "../hook/ws.jsonrpc.hook";
import { userStore } from "../store/user.store";
import { formatCurrency } from "../utils/CurrencyUtils";
import { OrderTimer } from "./timer";
import { AddPayment } from "../order-monitor/add.payment";
import { Modal } from "../component/common/Modal";
var logger = require("js-logger").get("Order Details");
var OrderItemsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #8e8b8b;\n  font-weight: normal;\n\n"], ["\n  color: #8e8b8b;\n  font-weight: normal;\n\n"])));
var OrderItemsContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0.5rem;\n  border:#e0e0e0 solid 1px;\n  border-radius: 0.5rem;\n  margin-bottom: 1rem;\n"], ["\n  width: 100%;\n  padding: 0.5rem;\n  border:#e0e0e0 solid 1px;\n  border-radius: 0.5rem;\n  margin-bottom: 1rem;\n"])));
var OrderItemContainer = styled(Box)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #000;\n"], ["\n  color: #000;\n"])));
var OrderItemOptionsContainer = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 15px;\n  flex-direction: column;\n"], ["\n  margin-left: 15px;\n  flex-direction: column;\n"])));
var OrderItem = React.memo(function (props) {
    var item = props.item, removeItem = props.removeItem, updateCount = props.updateCount;
    var currency = userStore(function (state) { return state.company.currency || "USD"; });
    return React.createElement(React.Fragment, null,
        React.createElement(OrderItemContainer, { gap: "0.5rem" },
            React.createElement(Box, { flex: 1 },
                item.count,
                " X ",
                item.name),
            React.createElement(Box, null, formatCurrency(item.price * item.count, currency)),
            React.createElement(Box, null, item.status)),
        item.options && React.createElement(OrderItemOptionsContainer, null, item.options.map(function (opt) { return React.createElement(Box, { flex: 1, key: opt.id },
            React.createElement(Box, { flex: "none" },
                opt.optionName,
                ":"),
            React.createElement(Box, { flex: 1 },
                opt.count,
                " X ",
                opt.name),
            React.createElement(Box, { flex: "none" }, formatCurrency(opt.price * opt.count, currency))); })));
});
var OrderItemsTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-bottom: 5px;\n"], ["\n  margin-bottom: 5px;\n"])));
var Total = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 0.5rem;\n  padding-top: 0.5rem;\n  border-top: #ccc solid 1px;\n"], ["\n  margin-top: 0.5rem;\n  padding-top: 0.5rem;\n  border-top: #ccc solid 1px;\n"])));
var getTotal = function (item) {
    var items = item.items;
    var prices = [];
    items.forEach(function (item) {
        prices.push(item.price * item.count);
        item.options.forEach(function (opt) {
            prices.push(opt.price * opt.count);
        });
    });
    return prices.reduce(function (a, b) { return a + b; }, 0);
};
export var OmOrderItems = React.memo(function (props) {
    var item = props.item, updateItemCount = props.updateItemCount, removeItem = props.removeItem;
    var items = item.items;
    var t = useTranslation().t;
    var currency = userStore(function (state) { return state.company.currency || "USD"; });
    return React.createElement(OrderItemsContainer, null,
        React.createElement(SubTitile, null, t("order.details.items.title.text")),
        React.createElement(OrderItemsContent, null,
            items.map(function (item) { return React.createElement(OrderItem, { item: item, key: item.id, removeItem: function () {
                    removeItem(item);
                }, updateCount: function (count) {
                    updateItemCount(item, count);
                } }); }),
            React.createElement(Total, null,
                t("order.total.text"),
                ": ",
                formatCurrency(getTotal(item), currency))));
});
var OrderInfoContainer = styled(Box)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 5px;\n  color: #000\n"], ["\n  margin-top: 5px;\n  color: #000\n"])));
var OrderInfoRow = styled(Box)(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
var OrderInfoRowLabel = styled(Box)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-style: italic\n"], ["\n  font-style: italic\n"
    //const OrderInfoRowContainer = styled(Box)
])));
//const OrderInfoRowContainer = styled(Box)
export var OrderInfo = React.memo(function (props) {
    var order = props.order;
    var info = order.info;
    var t = useTranslation().t;
    if (!info) {
        return null;
    }
    return React.createElement(OrderItemContainer, { flexDirection: "column" },
        React.createElement(SubTitile, null, t("order.details.info.title.text")),
        React.createElement(OrderItemsContent, null,
            React.createElement(OrderInfoContainer, { flexDirection: "column" },
                React.createElement(OrderInfoRow, null,
                    React.createElement(OrderInfoRowLabel, null,
                        t("order.type.label"),
                        ": "),
                    React.createElement("div", null, info.type)),
                React.createElement(OrderInfoRow, null,
                    React.createElement(OrderInfoRowLabel, null,
                        t("order.table.label"),
                        ": "),
                    React.createElement("div", null, info.place)),
                info.comment && React.createElement(OrderInfoRow, null,
                    React.createElement(OrderInfoRowLabel, null,
                        t("order.comment.label"),
                        ": "),
                    React.createElement("div", null, info.comment)),
                info.when && React.createElement(OrderInfoRow, null,
                    React.createElement(OrderInfoRowLabel, null,
                        t("order.when.label"),
                        ": "),
                    React.createElement("div", null, info.when)),
                info.address && React.createElement(OrderInfoRow, null,
                    React.createElement(OrderInfoRowLabel, null,
                        t("order.address.label"),
                        ": "),
                    React.createElement("div", null, info.address)),
                info.from && React.createElement(OrderInfoRow, null,
                    React.createElement(OrderInfoRowLabel, null,
                        t("order.from.label"),
                        ": "),
                    React.createElement("div", null, info.from)),
                info.name && React.createElement(OrderInfoRow, null,
                    React.createElement(OrderInfoRowLabel, null,
                        t("order.name.label"),
                        ": "),
                    React.createElement("div", null, info.name)),
                info.recipient && React.createElement(OrderInfoRow, null,
                    React.createElement(OrderInfoRowLabel, null,
                        t("order.recipient.label"),
                        ": "),
                    React.createElement("div", null, info.recipient)))));
});
var OrderHistoryContainer = styled(Box)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  flex-direction: column;\n"], ["\n  flex-direction: column;\n"])));
var OrderHistoryItem = styled(Box)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n\n"], ["\n\n"])));
export var OrderHistory = React.memo(function (props) {
    var order = props.order;
    var t = useTranslation().t;
    if (!order.history.length) {
        return null;
    }
    return React.createElement(OrderItemContainer, { flexDirection: "column" },
        React.createElement(SubTitile, null, t("order.details.history.title.text")),
        React.createElement(OrderItemsContent, null,
            React.createElement(OrderHistoryContainer, null, order.history.map(function (history) { return React.createElement(OrderHistoryItem, null, t("order.history.tpl", {
                currentStatus: history.currentStatus,
                previousStatus: history.previousStatus,
                date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                user: history.userName
            })); }))));
});
var Container = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var SubTitile = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  font-size: 0.9rem;\n  font-weight: bold;\n  color: #080808;\n"], ["\n  font-size: 0.9rem;\n  font-weight: bold;\n  color: #080808;\n"])));
var Type = styled(Box)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    background:", ";\n    border-radius: 0.5rem;\n    color: #fff;\n    margin:0 0 1rem 0;\n    display: inline-flex;\n    padding-right: 1rem;\n"], ["\n    background:", ";\n    border-radius: 0.5rem;\n    color: #fff;\n    margin:0 0 1rem 0;\n    display: inline-flex;\n    padding-right: 1rem;\n"])), function (props) { return props.type == "deliver" ? "#3e9bb8" : props.type == "outside" ? "#d61075" : "#2ebb8e"; });
var Icon = styled(Box)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    width: 3rem;\n    height: 3rem;    \n"], ["\n    width: 3rem;\n    height: 3rem;    \n"])));
var SvgIcon = styled(Box)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    width: 50%;\n    height: 50%;\n    background: url(\"", "\") no-repeat center center;  \n    background-size: contain;  \n"], ["\n    width: 50%;\n    height: 50%;\n    background: url(\"", "\") no-repeat center center;  \n    background-size: contain;  \n"])), function (props) { return props.icon; });
var Buttons = styled(Box)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  margin: 1rem 0;\n  justify-content: center\n"], ["\n  margin: 1rem 0;\n  justify-content: center\n"])));
var getNextStatus = function (status) {
    switch (status) {
        case OrderStatus.NEW:
            return OrderStatus.IN_PROGRESS;
        case OrderStatus.IN_PROGRESS:
            return OrderStatus.READY;
        case OrderStatus.READY:
            return OrderStatus.COMPLETED;
        default:
            return null;
    }
};
var getPrevStatus = function (status) {
    switch (status) {
        case OrderStatus.COMPLETED:
            return OrderStatus.READY;
        case OrderStatus.READY:
            return OrderStatus.IN_PROGRESS;
        case OrderStatus.IN_PROGRESS:
            return OrderStatus.NEW;
        default:
            return null;
    }
};
var StatusLabel = styled(Box)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  display: inline-flex;\n  background: #f3a541;\n  color: #fff;\n  padding: 0.5rem;\n  border-radius: 0.3rem;\n  margin-bottom: 0.5rem;\n  flex: none;\n"], ["\n  display: inline-flex;\n  background: #f3a541;\n  color: #fff;\n  padding: 0.5rem;\n  border-radius: 0.3rem;\n  margin-bottom: 0.5rem;\n  flex: none;\n"])));
export var OmDetails = React.memo(function (props) {
    var item = props.item, onStatusChange = props.onStatusChange;
    var order = item.order, items = item.items, status = item.status;
    var type = order.info.type.toLowerCase();
    var t = useTranslation().t;
    var jsonRpc = useJsonRPC();
    var companyId = userStore(function (state) { return state.company.id; });
    var _a = React.useState(null), addOrderPayment = _a[0], setAddOrderPayment = _a[1];
    // const [play] = useSound(notificationSound);
    logger.info("Order", order);
    var checkIsOrderPaid = function (order, status) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(status == "in-progress")) return [3 /*break*/, 2];
                    return [4 /*yield*/, jsonRpc.request("isOrderHasPayment", {
                            companyId: companyId,
                            status: status,
                            orderId: order.id
                        })];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        setAddOrderPayment({
                            order: order,
                            status: status
                        });
                        return [2 /*return*/, false];
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/, true];
            }
        });
    }); };
    var markOrderAsPaid = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, jsonRpc.request("markOrderAsPaid", {
                        companyId: companyId,
                        orderId: addOrderPayment.order.id,
                        paymentMethod: data.paymentMethod,
                        providerData: data.notes ? { notes: data.notes } : null
                    })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, updateOrderStatus(addOrderPayment.order, addOrderPayment.status)()];
                case 2:
                    _a.sent();
                    setAddOrderPayment(null);
                    return [2 /*return*/];
            }
        });
    }); };
    var updateOrderStatus = function (order, status) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkIsOrderPaid(order, status)];
                case 1:
                    // play();
                    if (!(_a.sent())) {
                        return [2 /*return*/];
                    }
                    jsonRpc.request("changeCompanyOrderStatus", {
                        companyId: companyId,
                        status: status,
                        orderId: order.id
                    }).then(onStatusChange);
                    return [2 /*return*/];
            }
        });
    }); }; };
    var updateOrderItemStatus = function (order, status) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        var queue;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkIsOrderPaid(order, status)];
                case 1:
                    // play();
                    if (!(_a.sent())) {
                        return [2 /*return*/];
                    }
                    queue = new PromiseQueue();
                    items.forEach(function (item) {
                        queue.add(function () {
                            return jsonRpc.request("changeCompanyOrderItemStatus", {
                                companyId: companyId,
                                status: status,
                                orderId: order.id,
                                id: item.id
                            });
                        });
                    });
                    queue.execute()
                        .then(onStatusChange)
                        .catch(function (err) {
                        logger.error(err);
                    });
                    return [2 /*return*/];
            }
        });
    }); }; };
    var updateStatusBuilder = function (order, status) {
        if (status == OrderStatus.NEW) {
            return updateOrderStatus(order, status);
        }
        return updateOrderItemStatus(order, status);
    };
    var updateOrderItemCount = function (item, count) {
        console.log("updateOrderItemCount", item, count);
    };
    var removeOrderItem = function (item) {
        console.log("removeOrderItem", item);
    };
    var nextStatus = getNextStatus(status);
    var prevStatus = getPrevStatus(status);
    return React.createElement(Container, null,
        React.createElement(Scrollbars, null,
            React.createElement(Type, { type: order.info.type.toLocaleLowerCase(), align: "center" },
                React.createElement(Icon, { align: "center", justify: "center" },
                    React.createElement(SvgIcon, { icon: type == "inside" ? insideSvg : type == "outside" ? takeoutSvg : deliverSvg })),
                t("order.type.".concat(type, ".text"))),
            React.createElement(Box, null,
                React.createElement(Box, { flex: 1 },
                    React.createElement(StatusLabel, null,
                        dayjs.unix(order.updated / 1000).format("D MMM YYYY HH:mm:ss"),
                        " ",
                        status)),
                React.createElement(Box, { flex: "none" },
                    React.createElement(OrderTimer, { order: order }))),
            React.createElement(OmOrderItems, { item: item, removeItem: removeOrderItem, updateItemCount: updateOrderItemCount }),
            React.createElement(OrderInfo, { order: order }),
            React.createElement(Buttons, { gap: "1rem" },
                "new" === status && React.createElement(Button, { color: "secondary", size: "sm", onClick: updateOrderStatus(order, OrderStatus.CANCELED) }, t("cancel.text")),
                prevStatus && React.createElement(Button, { color: "secondary", size: "sm", onClick: updateOrderStatus(order, prevStatus) }, t("order.button.revert.text")),
                nextStatus && React.createElement(Button, { color: "primary", size: "sm", onClick: updateStatusBuilder(order, nextStatus) }, t("order.button.".concat(status, ".text")))),
            React.createElement(OrderHistory, { order: order })),
        addOrderPayment && React.createElement(Modal, { visible: true },
            React.createElement(AddPayment, { onSubmit: markOrderAsPaid, onCancel: function () {
                    setAddOrderPayment(null);
                } })));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18;
