import * as React from "react";
import { CFormSelect, CFormLabel } from "@coreui/react";
import { randomString } from "../../utils/StringUtils";
export var SelectInput = React.memo(function (props) {
    var label = props.label, value = props.value, disabled = props.disabled, options = props.options, placeholder = props.placeholder, style = props.style, onChange = props.onChange, onBlur = props.onBlur, selectRef = props.selectRef, invalid = props.invalid;
    var inputId = props.id ? props.id : randomString(6);
    if (placeholder && !options.find(function (option) { return option.value == ""; })) {
        options.unshift({
            label: placeholder,
            value: -1
        });
    }
    return React.createElement(React.Fragment, null,
        label && React.createElement(CFormLabel, { htmlFor: inputId }, label),
        React.createElement(CFormSelect, { onChange: onChange, onBlur: onBlur, value: value, disabled: disabled, id: inputId, ref: selectRef, options: options, invalid: invalid, 
            //valid={fieldState.isDirty && !fieldState.invalid}
            style: style, size: "sm" }));
});
