var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { Section } from "../common/Section";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Box } from "../common/FlexItem";
import { HttpApiRequest, useHttpRequesPrepareHandler } from "../../service/network.service";
import { CFormSwitch } from "@coreui/react";
import { Button } from "../../common/Button";
import LoadingBox from "../common/LoadingBox";
import { MobileFormTextInput } from "../common/form/MobileFormTextInput";
import { useForm } from "react-hook-form";
import { getCompanyOrderSettings, updateCompanyOrderSettings } from "../../request/company.request";
import FormCheckboxInput from "../common/form/FormCheckboxInput";
import FormSelectInput from "../common/form/FormSelectInput";
var logger = require("js-logger").get("order.settings");
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 1rem;\n    flex: 1;\n    flex-direction: column;\n    height: 100%;\n    // width: 100%;\n"], ["\n    padding: 1rem;\n    flex: 1;\n    flex-direction: column;\n    height: 100%;\n    // width: 100%;\n"])));
var ItemsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    margin-top: 20px;\n"], ["\n    margin-top: 20px;\n"])));
var UserInputText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-size: 1rem;\n    width: 170px;\n"], ["\n    font-size: 1rem;\n    width: 170px;\n"])));
var TipFormItem = React.memo(function (props) {
    var t = useTranslation().t;
    var item = props.item, onChange = props.onChange, globalActive = props.globalActive;
    var userInput = item.userInput;
    return React.createElement(Box, { gap: "10px", align: "center", style: {
            margin: "5px 0"
        } },
        !userInput && React.createElement(MobileFormTextInput, { label: t("tips.percents.label"), value: item.percent, onChange: function (e) {
                var percent = parseInt(e.target.value);
                if (percent > 0) {
                    item.percent = percent;
                }
                onChange(item);
            }, style: {
                flex: 0,
                width: "100px"
            } }),
        userInput && React.createElement(UserInputText, null, "User Input"),
        React.createElement(CFormSwitch, { disabled: !globalActive, label: t("page.company.tips.enable.label"), checked: item.enabled, onChange: function (e) {
                item.enabled = !item.enabled;
                onChange(item);
            } }));
});
export var OrderSettingsPage = React.memo(function () {
    var t = useTranslation().t;
    var _a = React.useState(null), settings = _a[0], setSettings = _a[1];
    var _b = React.useState(false), active = _b[0], setActive = _b[1];
    var _c = React.useState(false), dirty = _c[0], setDirty = _c[1];
    var _d = useForm(), control = _d.control, formState = _d.formState, handleSubmit = _d.handleSubmit, reset = _d.reset;
    var orderSettingsState = useHttpRequesPrepareHandler();
    var loadSettings = React.useCallback(function () {
        orderSettingsState.run(getCompanyOrderSettings())
            .then(function (settings) {
            logger.info("OS", settings);
            if (!settings) {
                settings = {};
            }
            if (!settings.orderAutoCompleteDelay) {
                settings.orderAutoCompleteDelay = "PT4H";
            }
            reset(settings);
        })
            .catch(function (err) {
            logger.error(err);
        });
    }, []);
    var updateSettings = function (values) {
        //logger.info("VALUES", values);
        HttpApiRequest(updateCompanyOrderSettings(values)).promise.then(loadSettings)
            .catch(function (err) {
            logger.error(err);
        });
    };
    React.useEffect(function () {
        loadSettings();
    }, []);
    return React.createElement(Container, null,
        orderSettingsState.inProgress && React.createElement(LoadingBox, null),
        React.createElement("form", { onSubmit: handleSubmit(updateSettings), style: { flex: 1, display: "flex", "flexDirection": "column" } },
            React.createElement(Section, { title: t("order.settings.page.title") },
                React.createElement(FormCheckboxInput, { control: control, name: "enableName", label: t("order.settings.page.show.name.label") }),
                React.createElement(FormCheckboxInput, { control: control, name: "enablePhone", label: t("order.settings.page.show.phone.label") }),
                React.createElement(Box, { flexDirection: "column" },
                    React.createElement(FormSelectInput, { control: control, name: "orderAutoCompleteDelay", label: t("order.settings.page.autocomplete.label"), options: [
                            { label: "30m", value: "PT30M" },
                            { label: "1h", value: "PT1H" },
                            { label: "2h", value: "PT2H" },
                            { label: "4h", value: "PT4H" },
                            { label: "12h", value: "PT12H" },
                            { label: "24h", value: "P1D" }
                        ] }),
                    React.createElement("div", null, t("order.settings.page.autocomplete.desc")))),
            React.createElement(Button, { color: "primary", disabled: !formState.isDirty || !formState.isValid, type: "submit" }, t("save.text"))));
});
var templateObject_1, templateObject_2, templateObject_3;
