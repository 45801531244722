export var Feature;
(function (Feature) {
    Feature["WEBSITE"] = "website";
    Feature["MULTI_LANGUAGE_MENU"] = "multilanguage_menu";
    Feature["BASIC_MENU"] = "basic_menu";
    Feature["INTERACTIVE_ORDERING"] = "interactive_ordering";
    Feature["FULL_AUTOMATION"] = "full_automation";
    Feature["SHOPPING_CART"] = "shopping_cart";
    Feature["OFFLINE_PAYMENT"] = "offline_payment";
    Feature["OTHER_PAYMENT"] = "other_payment";
    Feature["ONLINE_PAYMENT"] = "online_payment";
})(Feature || (Feature = {}));
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["CASH"] = "cash";
    PaymentMethod["POS"] = "pos";
})(PaymentMethod || (PaymentMethod = {}));
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["IN_PROGRESS"] = "in-progress";
    PaymentStatus["FAILED"] = "failed";
    PaymentStatus["REQUIRES_CAPTURE"] = "requires_capture";
    PaymentStatus["CANCELED"] = "canceled";
    PaymentStatus["COMPLETED"] = "completed";
    PaymentStatus["ACTION"] = "action";
    PaymentStatus["REFUNDED"] = "refunded";
})(PaymentStatus || (PaymentStatus = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["NEW"] = "new";
    OrderStatus["IN_PROGRESS"] = "in-progress";
    OrderStatus["READY"] = "ready";
    OrderStatus["COMPLETED"] = "completed";
    OrderStatus["CANCELED"] = "canceled";
})(OrderStatus || (OrderStatus = {}));
