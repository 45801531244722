var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import axios from "axios";
import * as _ from "underscore";
import { userStore } from "../store/user.store";
import { EventEmitter } from "eventemitter3";
var logger = require("js-logger").get("network.service");
export var NetworkEvents;
(function (NetworkEvents) {
    NetworkEvents["POST_REQUEST_SUCCESS"] = "postRequestSuccess";
    NetworkEvents["POST_REQUEST_ERROR"] = "postRequestError";
    NetworkEvents["API_POST_REQUEST_SUCCESS"] = "apiPostRequestSuccess";
    NetworkEvents["API_POST_REQUEST_ERROR"] = "apiPostRequestError";
})(NetworkEvents || (NetworkEvents = {}));
var SAVE_METHODS = ["post", "put"];
var networkEventListener = new EventEmitter();
export var useNetworkEvents = function () {
    return networkEventListener;
};
export var emitApiPostRequestError = function (err) {
    networkEventListener.emit(NetworkEvents.API_POST_REQUEST_ERROR, err);
};
export var emitApiPostRequestSuccess = function (resp) {
    networkEventListener.emit(NetworkEvents.API_POST_REQUEST_SUCCESS, resp);
};
export function HttpRequest(config) {
    var cancelTokenSource = axios.CancelToken.source();
    config = _.extend({}, config, {
        cancelToken: cancelTokenSource.token
    });
    return {
        cancel: function () {
            cancelTokenSource.cancel();
        },
        promise: new Promise(function (res, rej) {
            axios(config)
                .then(function (resp) {
                if (config.method && SAVE_METHODS.includes(config.method.toLowerCase())) {
                    networkEventListener.emit(NetworkEvents.POST_REQUEST_SUCCESS, resp);
                }
                res(resp);
            })
                .catch(function (err) {
                networkEventListener.emit(NetworkEvents.POST_REQUEST_ERROR, err);
                rej(err);
            });
        })
    };
}
export function HttpApiRequest(config, silent) {
    if (silent === void 0) { silent = false; }
    var cancelTokenSource = axios.CancelToken.source();
    config = _.extend({}, config, {
        cancelToken: cancelTokenSource.token
    });
    if (!config.params) {
        config.params = {};
    }
    config.params["hl"] = userStore.getState().language;
    if (DEBUG_COMPANY && !config.params.company) {
        config.params["company"] = DEBUG_COMPANY;
    }
    return {
        cancel: function () {
            cancelTokenSource.cancel();
        },
        promise: new Promise(function (res, rej) {
            axios(config)
                .then(function (resp) {
                logger.info("Config", config);
                if (config.method && SAVE_METHODS.includes(config.method.toLowerCase()) && !silent) {
                    networkEventListener.emit(NetworkEvents.API_POST_REQUEST_SUCCESS, resp);
                }
                res(resp);
            })
                .catch(function (err) {
                if (!silent) {
                    networkEventListener.emit(NetworkEvents.API_POST_REQUEST_ERROR, err);
                }
                rej(err);
            });
        })
    };
}
export function useHttpRequestHandler(config, defaultValue) {
    var _this = this;
    var _a = React.useState(false), inProgress = _a[0], setInProgress = _a[1];
    var _b = React.useState(defaultValue), result = _b[0], setResult = _b[1];
    var _c = React.useState(undefined), error = _c[0], setError = _c[1];
    var _d = React.useState(false), executed = _d[0], setExecuted = _d[1];
    var run = function () { return __awaiter(_this, void 0, void 0, function () {
        var finish, res, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setInProgress(true);
                    setExecuted(false);
                    finish = function () {
                        setInProgress(false);
                        setExecuted(true);
                    };
                    setResult(defaultValue);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, HttpApiRequest(config).promise];
                case 2:
                    res = _a.sent();
                    setResult(res.data);
                    finish();
                    return [2 /*return*/, res.data];
                case 3:
                    err_1 = _a.sent();
                    finish();
                    setError(err_1);
                    throw err_1;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        run();
    }, []);
    return {
        inProgress: inProgress,
        result: result,
        error: error,
        executed: executed,
        run: run
    };
}
export function useHttpRequesPrepareHandler(defaultValue) {
    var _this = this;
    var _a = React.useState(false), inProgress = _a[0], setInProgress = _a[1];
    var _b = React.useState(defaultValue), result = _b[0], setResult = _b[1];
    var _c = React.useState(undefined), error = _c[0], setError = _c[1];
    var _d = React.useState(false), executed = _d[0], setExecuted = _d[1];
    var run = function (config, silent) { return __awaiter(_this, void 0, void 0, function () {
        var finish, res, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setInProgress(true);
                    setExecuted(false);
                    finish = function () {
                        setInProgress(false);
                        setExecuted(true);
                    };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, HttpApiRequest(config, silent).promise];
                case 2:
                    res = _a.sent();
                    setResult(res.data);
                    finish();
                    return [2 /*return*/, res.data];
                case 3:
                    err_2 = _a.sent();
                    setError(err_2);
                    finish();
                    throw err_2;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return {
        inProgress: inProgress,
        result: result,
        error: error,
        executed: executed,
        run: run
    };
}
