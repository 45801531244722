import * as React from "react";
import { useController } from "react-hook-form";
import { SelectInput } from "../SelectInput";
var FormSelectInput = function (props) {
    var label = props.label, name = props.name, control = props.control, defaultValue = props.defaultValue, rules = props.rules, disabled = props.disabled, options = props.options, placeholder = props.placeholder, style = props.style, id = props.id;
    if (placeholder && !options.find(function (option) { return option.value == null; })) {
        options.unshift({
            label: placeholder,
            value: null
        });
    }
    var _a = useController({
        name: name,
        control: control,
        defaultValue: defaultValue,
        rules: rules
    }), _b = _a.field, onChange = _b.onChange, onBlur = _b.onBlur, _c = _b.value, value = _c === void 0 ? null : _c, ref = _b.ref, fieldState = _a.fieldState;
    return React.createElement(SelectInput, { onBlur: onBlur, onChange: onChange, value: value, invalid: fieldState.error !== undefined, options: options, id: id, style: style, selectRef: ref, label: label, disabled: disabled });
};
export default React.memo(FormSelectInput);
