import * as React from "react";
import { Editor, EditorState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Box } from "../component/common/FlexItem";
import { CButton } from "@coreui/react";
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { useTranslation } from "react-i18next";
var logger = require("js-logger").get("content.editor");
export var ContentEditor = React.memo(function (props) {
    var value = props.value, onChange = props.onChange;
    var _a = React.useState(function () { return EditorState.createWithContent(stateFromHTML(value ? value : "")); }), editorState = _a[0], setEditorState = _a[1];
    var t = useTranslation().t;
    React.useEffect(function () {
        if (onChange) {
            onChange(stateToHTML(editorState.getCurrentContent()));
            //console.log(stateToHTML(state.getCurrentContent()));
        }
    }, [editorState]);
    // React.useEffect(() => {
    //     if(value){
    //         setEditorState(EditorState.createWithContent(stateFromHTML(value)))
    //     } else {
    //         setEditorState(EditorState.createEmpty())
    //     }
    // }, [value])
    var handleChange = function (state) {
        logger.info("Change", stateToHTML(state.getCurrentContent()));
        setEditorState(state);
    };
    var handleBold = function () {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'));
    };
    var handleItalic = function () {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'));
    };
    var handleUnderline = function () {
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'));
    };
    var handleKeyCommand = function (command, editorState) {
        var newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };
    return React.createElement(Box, { flexDirection: "column" },
        React.createElement(Box, { gap: "5px" },
            React.createElement(CButton, { color: "primary", onClick: handleBold }, t("bold.text")),
            React.createElement(CButton, { color: "primary", onClick: handleItalic }, t("italic.text")),
            React.createElement(CButton, { color: "primary", onClick: handleUnderline }, t("underline.text"))),
        React.createElement(Editor, { editorState: editorState, onChange: handleChange, handleKeyCommand: handleKeyCommand }));
});
