var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { reportService } from "../service/report.service";
import * as dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingBox from "../component/common/LoadingBox";
import { HorizontalDivider } from "../component/common/Divider";
import { Currency } from "../component/settings/TariffPlans";
import { userStore } from "../store/user.store";
import { flatten, groupBy } from "underscore";
import Scrollbars from "react-custom-scrollbars-2";
var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n"], ["\n    display: flex;\n    flex-direction: row;\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    padding: 0 2rem;\n    margin-top: 1rem;\n"], ["\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    padding: 0 2rem;\n    margin-top: 1rem;\n"])));
export var PageTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    padding: 0 10px;\n    font-size: 1.2rem;\n"], ["\n    padding: 0 10px;\n    font-size: 1.2rem;\n"])));
export var PageInfo = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var Heading1 = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 1.5rem;\n    font-weight: bold;\n"], ["\n    font-size: 1.5rem;\n    font-weight: bold;\n"])));
var Heading2 = styled(Heading1)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 1.2rem;    \n"], ["\n    font-size: 1.2rem;    \n"])));
var ReporContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-top: 1rem;\n    width: 35rem;\n    height: 100%;\n    // overflow: auto;\n    padding: 0 1rem 2rem 0;\n    \n"], ["\n    margin-top: 1rem;\n    width: 35rem;\n    height: 100%;\n    // overflow: auto;\n    padding: 0 1rem 2rem 0;\n    \n"])));
var Label = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    font-size: 1rem;\n    flex: 1\n"], ["\n    font-size: 1rem;\n    flex: 1\n"])));
var Value = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    font-size: 1rem    \n"], ["\n    font-size: 1rem    \n"])));
var Section = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    margin-top: 1.2rem\n"], ["\n    margin-top: 1.2rem\n"])));
var ReportBody = styled.table(templateObject_11 || (templateObject_11 = __makeTemplateObject([""], [""])));
var ReportRow = styled.tr(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    border-bottom: ", ";\n    td {\n        padding: ", ";        \n    }\n"], ["\n    border-bottom: ", ";\n    td {\n        padding: ", ";        \n    }\n"])), function (props) { return props.noBorder ? "none" : "#ccc solid 1px"; }, function (props) { return props.noMargin ? "0 5px" : "2px 5px"; });
var ReportCell = styled.td(templateObject_13 || (templateObject_13 = __makeTemplateObject([""], [""])));
var LabelCell = styled(ReportCell)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n    width: 100%;\n"], ["\n    width: 100%;\n"])));
var ValueCell = styled(ReportCell)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    white-space: nowrap;\n    text-align: ", ";\n"], ["\n    white-space: nowrap;\n    text-align: ", ";\n"])), function (props) { return props.align ? props.align : "right"; });
var AmountWithCurrency = React.memo(function (props) {
    var amount = props.amount, currency = props.currency;
    return React.createElement(React.Fragment, null,
        React.createElement(Currency, { currency: currency }),
        Number(amount).toFixed(2));
});
export var ZReportPage = React.memo(function () {
    var qParams = new URLSearchParams(document.location.search.replace("?", ""));
    var _a = React.useState(null), data = _a[0], setData = _a[1];
    var _b = React.useState(qParams.has("day") ? dayjs(qParams.get("day")).toDate() : dayjs().toDate()), day = _b[0], setDay = _b[1];
    var _c = React.useState(false), loading = _c[0], setLoading = _c[1];
    var loadReport = React.useCallback(function () {
        setLoading(true);
        reportService.getZReportOrders(dayjs(day).unix())
            .then(setData)
            .catch(function (err) {
            console.error(err);
        })
            .finally(function () {
            setLoading(false);
        });
    }, [day]);
    React.useEffect(function () {
        loadReport();
    }, [day]);
    return React.createElement(Container, null,
        React.createElement(Row, null,
            React.createElement(DatePicker, { selected: day, onChange: setDay, icon: React.createElement("div", null) })),
        React.createElement("div", { style: { flex: 1, overflow: "auto" } },
            loading && React.createElement(LoadingBox, null),
            !loading && data && React.createElement(ZReport, { data: data })));
});
var ZReport = React.memo(function (props) {
    var data = props.data;
    var date = data.date, orders = data.orders, VAT = data.VAT, currency = data.currency;
    var total = orders.map(function (order) { return order.total; }).reduce(function (a, b) { return a + b; }, 0);
    var taxes = parseFloat(Number(total * VAT).toFixed(2));
    var totalNet = total - taxes;
    var orderItems = flatten(orders.map(function (order) { return order.items; }));
    var orderItemsByCategory = groupBy(orderItems, "category");
    var orderItemsByName = groupBy(orderItems, "name");
    var totalTips = orders.map(function (order) { return order.tips; }).reduce(function (a, b) { return a + b; }, 0);
    var ordersByPaymentMethod = groupBy(orders, "paymentProvider");
    var ordersWithTips = orders.filter(function (order) { return order.tips > 0; });
    var ordersWithTipsByPaymentMethod = groupBy(ordersWithTips, "paymentProvider");
    return React.createElement(Scrollbars, null,
        React.createElement(ReporContainer, null,
            React.createElement(ReportBody, null,
                React.createElement(ReportRow, null,
                    React.createElement(LabelCell, null,
                        React.createElement(Heading1, null, "Z Report")),
                    React.createElement(ValueCell, null,
                        React.createElement(Heading1, null, dayjs.unix(date).format("YYYY-MM-DD")))),
                React.createElement(ReportRow, null,
                    React.createElement(LabelCell, null,
                        React.createElement(Label, null, "Income")),
                    React.createElement(ValueCell, null,
                        React.createElement(Value, null,
                            React.createElement(AmountWithCurrency, { amount: total, currency: currency })))),
                React.createElement(ReportRow, null,
                    React.createElement(LabelCell, null,
                        React.createElement(Label, null, "Total Net Sales")),
                    React.createElement(ValueCell, null,
                        React.createElement(Value, null,
                            React.createElement(AmountWithCurrency, { amount: totalNet, currency: currency })))),
                React.createElement(ReportRow, null,
                    React.createElement(LabelCell, null,
                        React.createElement(Label, null, "Tax")),
                    React.createElement(ValueCell, null,
                        React.createElement(Value, null,
                            React.createElement(AmountWithCurrency, { amount: taxes, currency: currency })))),
                React.createElement(ReportRow, null,
                    React.createElement(LabelCell, null,
                        React.createElement(Label, { style: {
                                fontWeight: "bold"
                            } }, "Total Sales")),
                    React.createElement(ValueCell, null,
                        React.createElement(Value, { style: {
                                fontWeight: "bold"
                            } },
                            React.createElement(AmountWithCurrency, { amount: totalNet + taxes, currency: currency }))))),
            React.createElement(Section, null,
                React.createElement(ReportBody, null,
                    React.createElement(ReportRow, null,
                        React.createElement(ReportCell, { colSpan: 4, style: {
                                textAlign: "center"
                            } },
                            React.createElement(Heading2, { style: {
                                    textTransform: "uppercase",
                                } }, "Receipts"))),
                    React.createElement(ReportRow, null,
                        React.createElement(LabelCell, null,
                            React.createElement(Label, null, "Description")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "Quantity")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "Amount")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "%"))),
                    Object.keys(ordersByPaymentMethod).map(function (provider) {
                        var total = ordersByPaymentMethod[provider].map(function (order) { return order.total; }).reduce(function (a, b) { return a + b; }, 0);
                        return React.createElement(ReportRow, null,
                            React.createElement(LabelCell, null,
                                React.createElement(Label, null, provider)),
                            React.createElement(ValueCell, { align: "center" },
                                React.createElement(Value, null, ordersByPaymentMethod[provider].length)),
                            React.createElement(ValueCell, null,
                                React.createElement(Value, null,
                                    React.createElement(AmountWithCurrency, { amount: total, currency: currency }))),
                            React.createElement(ValueCell, null,
                                React.createElement(Value, null,
                                    Number((total / totalNet) * 100).toFixed(0),
                                    "%")));
                    }))),
            React.createElement(Section, null,
                React.createElement(ReportBody, null,
                    React.createElement(ReportRow, null,
                        React.createElement(LabelCell, { colSpan: 4, style: {
                                textAlign: "center"
                            } },
                            React.createElement(Heading2, { style: {
                                    textTransform: "uppercase",
                                } }, "Tips"))),
                    React.createElement(ReportRow, null,
                        React.createElement(LabelCell, null,
                            React.createElement(Label, null, "Description")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "Quantity")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "Amount")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "%"))),
                    Object.keys(ordersWithTipsByPaymentMethod).map(function (provider) {
                        var total = ordersWithTipsByPaymentMethod[provider].map(function (order) { return order.tips; }).reduce(function (a, b) { return a + b; }, 0);
                        return React.createElement(ReportRow, null,
                            React.createElement(LabelCell, null,
                                React.createElement(Label, null, provider)),
                            React.createElement(ValueCell, { align: "center" },
                                React.createElement(Value, null, ordersWithTipsByPaymentMethod[provider].length)),
                            React.createElement(ValueCell, null,
                                React.createElement(Value, null,
                                    React.createElement(AmountWithCurrency, { amount: total, currency: currency }))),
                            React.createElement(ValueCell, null,
                                React.createElement(Value, null,
                                    Number((total / totalTips) * 100).toFixed(0),
                                    "%")));
                    }))),
            React.createElement(Section, null,
                React.createElement(ReportBody, null,
                    React.createElement(ReportRow, null,
                        React.createElement(ReportCell, { colSpan: 4, style: {
                                textAlign: "center"
                            } },
                            React.createElement(Heading2, { style: {
                                    textTransform: "uppercase",
                                } },
                                "VAT Extraction %",
                                VAT * 100))),
                    React.createElement(ReportRow, null,
                        React.createElement(LabelCell, null,
                            React.createElement(Label, null, "Description")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "Revenue Without VAT")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "VAT")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "Amount"))),
                    React.createElement(ReportRow, null,
                        React.createElement(LabelCell, null,
                            React.createElement(Label, null, "Items sold")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null,
                                React.createElement(AmountWithCurrency, { amount: totalNet, currency: currency }))),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null,
                                React.createElement(AmountWithCurrency, { amount: taxes, currency: currency }))),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null,
                                React.createElement(AmountWithCurrency, { amount: totalNet + taxes, currency: currency })))))),
            React.createElement(Section, null,
                React.createElement(ReportBody, null,
                    React.createElement(ReportRow, null,
                        React.createElement(ReportCell, { colSpan: 4, style: {
                                textAlign: "center"
                            } },
                            React.createElement(Heading2, { style: {
                                    textTransform: "uppercase",
                                } }, "Sales"))),
                    React.createElement(ReportRow, null,
                        React.createElement(LabelCell, null,
                            React.createElement(Label, null, "Category")),
                        React.createElement(ValueCell, { align: "left", style: {
                                width: "100px"
                            } },
                            React.createElement(Value, null, "Item")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "Quanity")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "Amount"))),
                    Object.keys(orderItemsByName).map(function (name) {
                        var items = orderItemsByName[name];
                        return React.createElement(ReportRow, null,
                            React.createElement(LabelCell, null,
                                React.createElement(Label, null, items[0].category)),
                            React.createElement(ValueCell, { align: "left" },
                                React.createElement(Value, null, name)),
                            React.createElement(ValueCell, { align: "center" },
                                React.createElement(Value, null, items.length)),
                            React.createElement(ValueCell, null,
                                React.createElement(Value, null,
                                    React.createElement(AmountWithCurrency, { amount: items.map(function (item) { return item.total; }).reduce(function (a, b) { return a + b; }), currency: currency }))));
                    }))),
            React.createElement(Section, null,
                React.createElement(ReportBody, null,
                    React.createElement(ReportRow, null,
                        React.createElement(ReportCell, { colSpan: 4 },
                            React.createElement(Heading2, { style: {
                                    textTransform: "uppercase",
                                    textAlign: "center"
                                } }, "Categories"))),
                    React.createElement(ReportRow, null,
                        React.createElement(LabelCell, null,
                            React.createElement(Label, null, "Category")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "Quanity")),
                        React.createElement(ValueCell, null,
                            React.createElement(Value, null, "Amount"))),
                    Object.keys(orderItemsByCategory).map(function (category) {
                        var items = orderItemsByCategory[category];
                        return React.createElement(ReportRow, null,
                            React.createElement(LabelCell, null,
                                React.createElement(Label, null, category)),
                            React.createElement(ValueCell, { align: "center" },
                                React.createElement(Value, null, items.length)),
                            React.createElement(ValueCell, null,
                                React.createElement(Value, null,
                                    React.createElement(AmountWithCurrency, { amount: items.map(function (item) { return item.total; }).reduce(function (a, b) { return a + b; }), currency: currency }))));
                    })))));
});
var ZReportV1 = React.memo(function (props) {
    var data = props.data;
    var day = data.day;
    var currency = userStore(function (state) { return state.company.currency; });
    //return <pre>{JSON.stringify(data, null, 4)}</pre>
    return React.createElement(ReporContainer, null,
        React.createElement(Row, null,
            React.createElement(Heading1, { style: { flex: 1 } }, "Z Report"),
            React.createElement(Heading1, null, dayjs.unix(day).format("YYYY-MM-DD"))),
        React.createElement(Row, null,
            React.createElement(HorizontalDivider, null)),
        React.createElement(Row, null,
            React.createElement(Heading2, { style: {
                    flex: 1,
                    textTransform: "uppercase",
                    textAlign: "center"
                } }, "Sales And Tax summary")),
        React.createElement(Row, null,
            React.createElement(HorizontalDivider, null)),
        React.createElement(Row, null,
            React.createElement(Label, null, "Total Net Sales"),
            React.createElement(Value, null,
                React.createElement(Currency, { currency: currency }),
                Number(data.totalNet).toFixed(2))),
        React.createElement(Row, null,
            React.createElement(Label, null, "Tax"),
            React.createElement(Value, null,
                React.createElement(Currency, { currency: currency }),
                data.taxes)),
        React.createElement(Row, null,
            React.createElement(HorizontalDivider, null)),
        React.createElement(Row, null,
            React.createElement(Label, { style: {
                    fontWeight: "bold"
                } }, "Total Sales"),
            React.createElement(Value, { style: {
                    fontWeight: "bold"
                } },
                React.createElement(Currency, { currency: currency }),
                data.totalNet + data.taxes)),
        React.createElement(Section, null,
            React.createElement(Row, { style: {
                    justifyContent: "center"
                } },
                React.createElement(Heading2, { style: {
                        textTransform: "uppercase",
                    } }, "Sales categories")),
            React.createElement(Row, { style: {
                    gap: "0.5rem"
                } },
                React.createElement(Label, null, "Category"),
                React.createElement(Value, null, "Quantity"),
                React.createElement(Value, null, "Net Sales")),
            React.createElement(Row, null,
                React.createElement(HorizontalDivider, null)),
            data.categoriesData.map(function (item) { return React.createElement(Row, null,
                React.createElement(Label, null, item.category_name),
                React.createElement(Value, null, item.category_items_count),
                React.createElement(Value, { style: {
                        width: "6rem",
                        textAlign: "right"
                    } },
                    React.createElement(Currency, { currency: currency }),
                    Number(item.category_total * (1 - data.VAT)).toFixed(2))); }),
            React.createElement(Row, null,
                React.createElement(HorizontalDivider, null)),
            React.createElement(Row, null,
                React.createElement(Label, { style: {
                        fontWeight: "bold"
                    } }, "Total Net Sales"),
                React.createElement(Value, { style: {
                        fontWeight: "bold"
                    } },
                    React.createElement(Currency, { currency: currency }),
                    Number(data.categoriesData.map(function (item) { return item.category_total; }).reduce(function (a, b) { return a + b; }, 0) * (1 - data.VAT)).toFixed(2)))),
        React.createElement(Section, null,
            React.createElement(Row, { style: {
                    justifyContent: "center"
                } },
                React.createElement(Heading2, { style: {
                        textTransform: "uppercase",
                    } }, "Payment Details")),
            React.createElement(Row, null,
                React.createElement(HorizontalDivider, null)),
            data.typesData.map(function (item) { return React.createElement(Row, null,
                React.createElement(Label, null, item.payment_type),
                React.createElement(Value, null,
                    React.createElement(Currency, { currency: currency }),
                    item.amount)); }),
            React.createElement(Row, null,
                React.createElement(HorizontalDivider, null)),
            React.createElement(Row, null,
                React.createElement(Label, { style: {
                        fontWeight: "bold"
                    } }, "Total Payments"),
                React.createElement(Value, { style: {
                        fontWeight: "bold"
                    } },
                    React.createElement(Currency, { currency: currency }),
                    data.typesData.map(function (item) { return item.amount; }).reduce(function (a, b) { return a + b; }, 0))),
            React.createElement(Row, null,
                React.createElement(Label, { style: {
                        fontWeight: "bold"
                    } }, "Total Payments - Total Sales = "),
                React.createElement(Value, { style: {
                        fontWeight: "bold"
                    } },
                    React.createElement(Currency, { currency: currency }),
                    Number(data.typesData.map(function (item) { return item.amount; }).reduce(function (a, b) { return a + b; }, 0) - data.totalNet).toFixed(2)))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
