var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import LoadingBox from "./LoadingBox";
import { Portal } from "./Portal";
var LoadingOverlayEl = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position:fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 10000001\n"], ["\n    position:fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 10000001\n"])));
var LoadingConainerEl = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position:fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 10000002\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position:fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 10000002\n"])));
var LoadingContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    width:  10rem;\n    height: 10rem;    \n    display: flex;\n    align-items: center;\n    justify-content: center;\n"], ["\n    width:  10rem;\n    height: 10rem;    \n    display: flex;\n    align-items: center;\n    justify-content: center;\n"])));
var LoadingOverlay = React.memo(function (props) {
    var style = props.style;
    return React.createElement(Portal, null,
        React.createElement(LoadingOverlayEl, null),
        React.createElement(LoadingConainerEl, null,
            React.createElement(LoadingContent, null,
                React.createElement(LoadingBox, { style: style }))));
});
export default LoadingOverlay;
var templateObject_1, templateObject_2, templateObject_3;
