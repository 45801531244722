var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ORDER_ENDPOINT } from "../constats/ApiEndpoints";
import { userStore } from "../store/user.store";
export function getOrdersRequest(afterCursor, beforeCursor) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    var params = {
        token: token
    };
    if (afterCursor) {
        params.afterCursor = afterCursor;
    }
    if (beforeCursor) {
        params.beforeCursor = beforeCursor;
    }
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(ORDER_ENDPOINT),
        params: params
    };
}
export function getOrdersByFilterRequest(filters, afterCursor, beforeCursor) {
    var token = userStore.getState().token;
    var companyId = userStore.getState().company.id;
    var params = {
        token: token
    };
    if (afterCursor) {
        params.afterCursor = afterCursor;
    }
    if (beforeCursor) {
        params.beforeCursor = beforeCursor;
    }
    var dateRange = filters.dateRange, rest = __rest(filters, ["dateRange"]);
    // const data = Object.assign({}, {
    //     dateRange: [parseInt(Number(dateRange[0].getTime()/1000).toFixed(0)), parseInt(Number(dateRange[1].getTime()/1000).toFixed())]
    // }, rest);
    return {
        url: "".concat(API_BASE_URL, "/").concat(companyId, "/").concat(ORDER_ENDPOINT),
        method: "POST",
        params: params,
        data: filters,
    };
}
