var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import styled from "styled-components";
import { OrderMonitor } from ".";
import { HasPemission } from "../common/user/HasPermission";
import { MonitorPermissions } from "../common/user/Permission";
import { Box } from "../component/common/FlexItem";
import LoadingBox from "../component/common/LoadingBox";
import { Modal } from "../component/common/Modal";
import { useOrderApiJsonRPC } from "../hook/order.api.jsonrpc";
import { userStore } from "../store/user.store";
import { AddPayment } from "./add.payment";
var logger = require("js-logger").get("OrderMonitorPage");
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding-top: 1rem;\n    height: 100%;\n"], ["\n    padding-top: 1rem;\n    height: 100%;\n"])));
export var OrderMonitorPage = React.memo(function (props) {
    var orderApi = useOrderApiJsonRPC();
    var _a = React.useState([]), orders = _a[0], setOrders = _a[1];
    var _b = React.useState(false), loading = _b[0], setLoading = _b[1];
    var _c = React.useState(null), addOrderPayment = _c[0], setAddOrderPayment = _c[1];
    var companyId = userStore(function (state) { return state.company.id; });
    var getCompanyOrders = function () {
        setLoading(true);
        orderApi.getCompanyOrders({ companyId: companyId })
            .then(function (response) {
            //logger.info(response)
            setOrders(response.orders);
        })
            .catch(function (err) {
            logger.error(err);
        })
            .finally(function () {
            setLoading(false);
        });
    };
    React.useEffect(function () {
        getCompanyOrders();
        return function () {
            orderApi.disconnect();
        };
    }, []);
    var updateOrderStatus = React.useCallback(function (order, status) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, orderApi.changeCompanyOrderStatus({
                    companyId: companyId,
                    status: status,
                    orderId: order.id
                }).then(function (order) {
                    if (!order) {
                        return;
                    }
                    var exists = orders.find(function (ord) { return ord.id == order.id; });
                    var idx = orders.indexOf(exists);
                    var update = orders.slice(0);
                    update[idx] = order;
                    setOrders(update);
                }).catch(function (err) {
                    logger.error("Error", err);
                })];
        });
    }); }, [orders]);
    var markOrderAsPaid = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, orderApi.markOrderAsPaid({
                        companyId: companyId,
                        orderId: addOrderPayment.order.id,
                        paymentMethod: data.paymentMethod,
                        providerData: data.notes ? { notes: data.notes } : null
                    })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, updateOrderStatus(addOrderPayment.order, addOrderPayment.status)];
                case 2:
                    _a.sent();
                    setAddOrderPayment(null);
                    return [2 /*return*/];
            }
        });
    }); };
    return React.createElement(HasPemission, { permission: MonitorPermissions.MONITOR_VIEW },
        React.createElement(Container, { flex: 1 },
            React.createElement(Scrollbars, { style: {
                    height: "100%",
                    width: "100%"
                } },
                React.createElement(OrderMonitor, { orders: orders, changeOrderStatus: function (order, status) { return __awaiter(void 0, void 0, void 0, function () {
                        var res;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(status == "in-progress")) return [3 /*break*/, 2];
                                    return [4 /*yield*/, orderApi.isOrderHasPayment({
                                            companyId: companyId,
                                            orderId: order.id
                                        })];
                                case 1:
                                    res = _a.sent();
                                    if (!res) {
                                        setAddOrderPayment({
                                            order: order,
                                            status: status
                                        });
                                    }
                                    _a.label = 2;
                                case 2: return [4 /*yield*/, updateOrderStatus(order, status)];
                                case 3:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); } }))),
        React.createElement(Modal, { visible: loading, fullscreen: true },
            React.createElement(LoadingBox, null)),
        addOrderPayment && React.createElement(Modal, { visible: true },
            React.createElement(AddPayment, { onSubmit: markOrderAsPaid, onCancel: function () {
                    setAddOrderPayment(null);
                } })));
});
var templateObject_1;
