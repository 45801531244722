var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import { OrderTable } from "./OrderTable";
import { Box } from "../component/common/FlexItem";
import styled from "styled-components";
import { PaymentStatus, OrderStatus } from "../common/type";
import { Modal, ModalBody, ModalHeader, ModalHeaderTitle } from "../component/common/Modal";
import { formatCurrency } from "../utils/CurrencyUtils";
import Scrollbars from "react-custom-scrollbars-2";
import { Button } from "../common/Button";
import { OrderHistory } from "../operation-monitor/detail";
import { DateRangeInput } from "./date.range.input";
import * as dayjs from "dayjs";
import { SelectInput } from "../component/common/SelectInput";
import { capitalize } from "../utils/StringUtils";
var Container = styled(Box)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    height: 100%;\n    position: relative;\n    padding: 0 1rem;\n"], ["\n    height: 100%;\n    position: relative;\n    padding: 0 1rem;\n"])));
var Row = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    display: inline-flex;\n    padding: 1rem 0;\n    border-bottom:", ";    \n"], ["\n    display: flex;\n    display: inline-flex;\n    padding: 1rem 0;\n    border-bottom:", ";    \n"])), function (props) { return props.noborder ? "none" : "#ccc solid 1px"; });
var TotalRow = styled(Row)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border-bottom: 0;\n    border-top: #ccc solid 1px;\n"], ["\n    border-bottom: 0;\n    border-top: #ccc solid 1px;\n"])));
var ChildRow = styled(Row)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    padding: 0.5rem 0;\n    margin-left: 0.5rem;\n"], ["\n    padding: 0.5rem 0;\n    margin-left: 0.5rem;\n"])));
var RowLabel = styled(Box)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    flex: 1\n"], ["\n    flex: 1\n"])));
var RowValue = styled(Box)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-weight: bold;\n"], ["\n    font-weight: bold;\n"])));
var Section = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-top: 1.5rem;\n"], ["\n    margin-top: 1.5rem;\n"])));
var SectionTitle = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    font-size: 0.8rem;\n    margin-bottom: 0.3rem;\n"], ["\n    font-size: 0.8rem;\n    margin-bottom: 0.3rem;\n"])));
// const OrderInfoContainer = styled.
var Buttons = styled(Box)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    margin: 1rem 0;\n    justify-content: center\n"], ["\n    margin: 1rem 0;\n    justify-content: center\n"])));
var OrderItem = React.memo(function (props) {
    var item = props.item, currency = props.currency;
    return React.createElement(React.Fragment, null,
        React.createElement(Row, { key: item.id, noborder: true },
            React.createElement(RowLabel, null,
                item.name,
                " X ",
                item.count),
            React.createElement(RowValue, { flex: "none" }, formatCurrency(item.price * item.count, currency))),
        item.options.map(function (option) { return React.createElement(ChildRow, { key: option.id, noborder: true },
            React.createElement(RowLabel, null,
                option.optionName,
                ": ",
                option.name,
                " x ",
                option.count),
            React.createElement(RowValue, { flex: "none" }, formatCurrency(option.price * option.count, currency))); }));
});
var OrderInfo = React.memo(function (props) {
    var order = props.order;
    var info = order.info;
    return React.createElement(Box, { flexDirection: "column" },
        React.createElement(Section, null,
            React.createElement(SectionTitle, null, "Delivery Info"),
            React.createElement(Box, { flexDirection: "column" },
                React.createElement(Row, null,
                    React.createElement(RowLabel, null, "Place"),
                    React.createElement(RowValue, null, info.place)),
                info.type == "Inside" && React.createElement(React.Fragment, null, info.comment && React.createElement(Row, null,
                    React.createElement(RowLabel, null, "Comment"),
                    React.createElement(RowValue, null, info.comment))),
                info.type == "Take away" && React.createElement(React.Fragment, null,
                    info.from && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "From"),
                        React.createElement(RowValue, null, info.from)),
                    info.when && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "When"),
                        React.createElement(RowValue, null, info.when)),
                    info.comment && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "Comment"),
                        React.createElement(RowValue, null, info.comment))),
                info.type == "Delivery" && React.createElement(React.Fragment, null,
                    info.from && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "From"),
                        React.createElement(RowValue, null, info.from)),
                    info.when && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "When"),
                        React.createElement(RowValue, null, info.when)),
                    info.address && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "Address"),
                        React.createElement(RowValue, null, info.address)),
                    info.recipient && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "Recipient"),
                        React.createElement(RowValue, null, info.recipient)),
                    info.comment && React.createElement(Row, null,
                        React.createElement(RowLabel, null, "Comment"),
                        React.createElement(RowValue, null, info.comment))))),
        (info.name || info.phone) && React.createElement(Section, null,
            React.createElement(SectionTitle, null, "Client Info"),
            React.createElement(Box, { flexDirection: "column" },
                info.name && React.createElement(Row, null,
                    React.createElement(RowLabel, null, "Name"),
                    React.createElement(RowValue, null, info.name)),
                info.phone && React.createElement(Row, null,
                    React.createElement(RowLabel, null, "Phone"),
                    React.createElement(RowValue, null, info.phone)))),
        !order.payment && React.createElement(Buttons, null,
            React.createElement(Button, { color: "primary" }, "Add Payment")),
        React.createElement(OrderHistory, { order: order }));
});
var getOrderTotal = function (items) {
    var prices = [];
    items.forEach(function (item) {
        prices.push(item.price * item.count);
        item.options.forEach(function (opt) {
            prices.push(opt.price * item.count);
        });
    });
    return prices.reduce(function (a, b) { return a + b; }, 0);
};
export var OrderPage = React.memo(function (props) {
    var _a = React.useState(null), selected = _a[0], setSelected = _a[1];
    var _b = React.useState([]), checked = _b[0], setChecked = _b[1];
    var _c = React.useState({
        dateRange: [dayjs().hour(0).minute(0).second(0).toDate(), dayjs().hour(23).minute(59).second(59).toDate()]
    }), filters = _c[0], setFilters = _c[1];
    return React.createElement(Container, { flex: 1, flexDirection: "column" },
        React.createElement(Box, { flexDirection: "row", gap: "1rem", style: {
                padding: "1rem 0"
            } },
            React.createElement(Box, { flexDirection: "column" },
                React.createElement(DateRangeInput, { label: "Date Range", range: filters.dateRange, onRangeChange: function (dateRange) {
                        console.log("Range", dateRange);
                        setFilters(Object.assign({}, filters, {
                            dateRange: dateRange.slice(0)
                        }));
                    } })),
            React.createElement(Box, { flexDirection: "column" },
                React.createElement(SelectInput, { placeholder: "Select Order Status", label: "Order Status", options: Object.values(OrderStatus).map(function (item) { return ({ label: capitalize(item), value: item }); }), onChange: function (e) {
                        console.log("Change", e, e.target.value);
                        setFilters(Object.assign({}, filters, {
                            orderStatus: e.target.value == -1 ? undefined : e.target.value
                        }));
                    } })),
            React.createElement(Box, { flexDirection: "column" },
                React.createElement(SelectInput, { placeholder: "Select Payment Status", label: "Payment Status", options: Object.values(PaymentStatus).map(function (item) { return ({ label: capitalize(item), value: item }); }), onChange: function (e) {
                        console.log("Change", e, e.target.value);
                        setFilters(Object.assign({}, filters, {
                            paymentStatus: e.target.value == -1 ? undefined : e.target.value
                        }));
                    } })),
            React.createElement(Box, { flexDirection: "column" },
                React.createElement(SelectInput, { placeholder: "Select Payment Provider", label: "Payment Provider", options: [
                        { label: "Cash", value: "cash" },
                        { label: "Credit Card", value: "credit_card" },
                        { label: "Online - CreditGuard", value: "creditguard" },
                        { label: "Online - Stripe", value: "strip" }
                    ], onChange: function (e) {
                        console.log("Change", e, e.target.value);
                        setFilters(Object.assign({}, filters, {
                            paymentProvider: e.target.value == -1 ? undefined : e.target.value
                        }));
                    } }))),
        React.createElement(OrderTable, { selected: selected, setSelected: setSelected, checked: checked, setChecked: setChecked, filters: filters }),
        selected && React.createElement(Modal, { visible: true },
            React.createElement(ModalHeader, { onClose: function () {
                    setSelected(null);
                } },
                React.createElement(ModalHeaderTitle, null, "Order #".concat(selected.name))),
            React.createElement(ModalBody, null,
                React.createElement(Scrollbars, null,
                    React.createElement(Box, { flexDirection: "column" },
                        React.createElement(Row, null,
                            React.createElement(RowLabel, null, "Type"),
                            React.createElement(RowValue, { flex: "none" }, selected.info.type)),
                        React.createElement(Section, null,
                            React.createElement(SectionTitle, null, "Goods"),
                            React.createElement(Box, { flexDirection: "column" },
                                selected.items.map(function (item) { return React.createElement(OrderItem, { key: item.id, item: item, currency: selected.currency }); }),
                                React.createElement(TotalRow, null,
                                    React.createElement(RowLabel, null, "Total"),
                                    React.createElement(RowValue, null, formatCurrency(getOrderTotal(selected.items), selected.currency))))),
                        selected.payment && React.createElement(Row, null,
                            React.createElement(RowLabel, null, "Payment"),
                            React.createElement(RowValue, null,
                                selected.payment.provider,
                                ", ",
                                selected.payment.status,
                                selected.payment.transactionId && React.createElement(React.Fragment, null,
                                    ", ",
                                    selected.payment.transactionId))),
                        React.createElement(OrderInfo, { order: selected }))))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
