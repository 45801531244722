import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CFormInput, CButton, CFormLabel } from "@coreui/react";
import { Popover } from "react-tiny-popover";
import * as dayjs from "dayjs";
import { Box } from "../component/common/FlexItem";
import { randomString } from "../utils/StringUtils";
export var getDefaultRange = function () {
    var today = dayjs().toDate();
    var minus7Days = dayjs().subtract(7, "day").toDate();
    return [minus7Days, today];
};
export var DateRangeInput = React.memo(function (props) {
    var onRangeChange = props.onRangeChange, label = props.label;
    var inputId = props.id ? props.id : randomString(6);
    //const [day, setDay] = React.useState<Date>(props.range[0]);
    var _a = React.useState(false), popoverVisible = _a[0], setPopoverVisible = _a[1];
    var _b = React.useState(props.range), range = _b[0], setRange = _b[1];
    var updateRange = React.useCallback(function (from, to) {
        var update = [dayjs(from).hour(0).minute(0).second(0).toDate(), dayjs(to).hour(23).minute(59).second(59).toDate()];
        setRange(update);
        onRangeChange(update);
        setPopoverVisible(false);
    }, [range]);
    React.useEffect(function () {
        setRange(props.range);
    }, [props.range]);
    var rangeValue = "".concat(dayjs(range[0]).format("YYYY-MM-DD HH:mm:ss"), "-");
    if (range[1]) {
        rangeValue += "".concat(dayjs(range[1]).format("YYYY-MM-DD HH:mm:ss"));
    }
    return React.createElement(Popover, { positions: ["bottom", "left"], isOpen: popoverVisible, onClickOutside: function () {
            setPopoverVisible(false);
        }, content: React.createElement(Box, { flexDirection: "row", style: {
                padding: "1rem 1rem",
                backgroundColor: "#fff"
            } },
            React.createElement(DatePicker, { inline: true, selected: range[0], startDate: range[0], endDate: range[1], selectsRange: true, onChange: function (dates) {
                    console.log("XXXXX", dates);
                    if (!dates[1]) {
                        setRange(dates);
                    }
                    else {
                        onRangeChange(dates);
                    }
                }, icon: React.createElement("div", null) }),
            React.createElement(Box, { flexDirection: "column", gap: "5px", style: {
                    padding: "1rem"
                } },
                React.createElement(CButton, { color: "primary", size: "small", onClick: function () {
                        var today = dayjs().toDate();
                        updateRange(today, today);
                    } }, "Today"),
                React.createElement(CButton, { size: "small", onClick: function () {
                        var yesterday = dayjs().subtract(1, "day").toDate();
                        updateRange(yesterday, yesterday);
                    } }, "Yesterday"),
                React.createElement(CButton, { size: "small", onClick: function () {
                        var today = dayjs().toDate();
                        var minus7Days = dayjs().subtract(7, "day").toDate();
                        updateRange(minus7Days, today);
                    } }, "Last 7 days"),
                React.createElement(CButton, { size: "small", onClick: function () {
                        var today = dayjs().toDate();
                        var minus14Days = dayjs().subtract(14, "day").toDate();
                        updateRange(minus14Days, today);
                    } }, "Last 14 days"),
                React.createElement(CButton, { size: "small", onClick: function () {
                        var today = dayjs().toDate();
                        var minus30Days = dayjs().subtract(30, "day").toDate();
                        updateRange(minus30Days, today);
                    } }, "Last 30 days"),
                React.createElement(CButton, { size: "small", onClick: function () {
                        var startWeek = dayjs().startOf("week").toDate();
                        var endWeek = dayjs().endOf("week").toDate();
                        updateRange(startWeek, endWeek);
                    } }, "Current Week"),
                React.createElement(CButton, { size: "small", onClick: function () {
                        var lastWeek = dayjs().subtract(1, "week");
                        var startWeek = lastWeek.startOf("week").toDate();
                        var endWeek = lastWeek.endOf("week").toDate();
                        updateRange(startWeek, endWeek);
                    } }, "Last Week"),
                React.createElement(CButton, { size: "small", onClick: function () {
                        var startMonth = dayjs().startOf("month").toDate();
                        var endMonth = dayjs().endOf("month").toDate();
                        updateRange(startMonth, endMonth);
                    } }, "Current Month"),
                React.createElement(CButton, { size: "small", onClick: function () {
                        var lastMonth = dayjs().subtract(1, "month");
                        var startMonth = lastMonth.startOf("month").toDate();
                        var endMonth = lastMonth.endOf("month").toDate();
                        updateRange(startMonth, endMonth);
                    } }, "Last Month"))) },
        React.createElement(React.Fragment, null,
            label && React.createElement(CFormLabel, { htmlFor: inputId }, label),
            React.createElement(CFormInput, { id: inputId, readOnly: true, disableHandleFocus: true, value: "".concat(dayjs(range[0]).format("YYYY-MM-DD HH:mm:ss"), "-").concat(dayjs(range[1]).format("YYYY-MM-DD HH:mm:ss")), size: "sm", onClick: function () {
                    setPopoverVisible(true);
                }, style: {
                    width: "19rem"
                } })));
});
