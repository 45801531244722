var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from "react";
import styled from "styled-components";
import { HoriontalContainer } from "../common/HorizontalContainer";
import { VerticalContainer } from "../common/VerticalContainer";
import { CButton, CFormCheck } from "@coreui/react";
import { Modal } from "../common/Modal";
import { SubscriptionPayment } from "../subscription/sub.payment";
import { SubscriptionService } from "../../service/subscription.service";
import { userStore } from "../../store/user.store";
import { userCompaniesRequest } from "../../request/user.request";
import { HttpApiRequest } from "../../service/network.service";
import { Box } from "../common/FlexItem";
var logger = require("js-logger").get("TariffPlans");
var PeriodValueMap = {
    "month": 1,
    "quarter": 2,
    "semi-annual": 3,
    "annual": 4
};
var TariffPlansContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    flex: 1;    \n"], ["\n    flex: 1;    \n"])));
var CurrentTariffPlanContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    display: flex;\n    border: orange solid 1px;\n    border-radius: 4px;\n    flex: 1;\n    padding: 5px 10px;\n    margin: 5px 0 15px 0;\n"], ["\n    display: flex;\n    border: orange solid 1px;\n    border-radius: 4px;\n    flex: 1;\n    padding: 5px 10px;\n    margin: 5px 0 15px 0;\n"])));
var TariffPlanContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    border:#ccc solid 1px;\n    border-radius: 4px;\n    flex: 1;\n    padding: 5px 10px;\n    margin: 5px 0;\n"], ["\n    display: flex;\n    border:#ccc solid 1px;\n    border-radius: 4px;\n    flex: 1;\n    padding: 5px 10px;\n    margin: 5px 0;\n"])));
var TariffPlanName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 16px;\n    text-transform: uppercase;\n    font-weight: bold\n"], ["\n    font-size: 16px;\n    text-transform: uppercase;\n    font-weight: bold\n"])));
var TariffPlanPrice = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 14px;\n    text-transform: uppercase;\n    font-weight: bold;\n    margin-right: 5px;\n"], ["\n    font-size: 14px;\n    text-transform: uppercase;\n    font-weight: bold;\n    margin-right: 5px;\n"])));
var SectionTitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 13px;\n    text-transform: uppercase;\n    color: #ccc;\n    border-bottom: #ccc solid 1px;\n    margin: 5px 0;\n"], ["\n    font-size: 13px;\n    text-transform: uppercase;\n    color: #ccc;\n    border-bottom: #ccc solid 1px;\n    margin: 5px 0;\n"])));
export var Currency = React.memo(function (props) {
    var currency = props.currency;
    if (currency == "USD") {
        return React.createElement("span", { dangerouslySetInnerHTML: { __html: "&dollar;" } });
    }
    if (currency == "EUR") {
        return React.createElement("span", { dangerouslySetInnerHTML: { __html: "&euro;" } });
    }
    if (currency == "ILS") {
        return React.createElement("span", { dangerouslySetInnerHTML: { __html: "&#8362;" } });
    }
    return React.createElement("span", null);
});
export var TariffPlans = React.memo(function (props) {
    var _a, _b;
    var _c = React.useState(null), sub = _c[0], setSub = _c[1];
    var company = userStore(function (state) { return state.company; });
    var setCompany = userStore(function (state) { return state.setCompany; });
    var _d = React.useState([]), subscriptions = _d[0], setSubscriptions = _d[1];
    var _e = React.useState(company.subscription.period || "month"), period = _e[0], setPeriod = _e[1];
    var subscriptionService = React.useMemo(function () {
        return new SubscriptionService();
    }, []);
    var loadSubscriptions = React.useCallback(function () {
        //console.log("Load subscriptions");
        subscriptionService
            .getSubscriptions()
            .promise
            .then(function (res) {
            setSubscriptions(res.data);
        })
            .catch(function (err) {
            console.error(err);
        });
    }, [subscriptions]);
    var updateUserSubscription = React.useCallback(function () {
        HttpApiRequest(userCompaniesRequest())
            .promise
            .then(function (res) {
            setCompany(res.data);
        });
    }, [company]);
    React.useEffect(loadSubscriptions, []);
    var subPrice = (_a = company === null || company === void 0 ? void 0 : company.subscription) === null || _a === void 0 ? void 0 : _a.price;
    var discount = ((_b = company === null || company === void 0 ? void 0 : company.subscription) === null || _b === void 0 ? void 0 : _b.discount["".concat(period.replace("-", "_"), "_discount")]) || 0;
    var currentDiscount = (company.subscription && company.subscription.discount["".concat(company.subscription.period.replace("-", "_"), "_discount")]) || 0;
    var currentPrice = Math.ceil(subPrice - (subPrice * (parseInt(currentDiscount.toString()) / 100)));
    var availableSubscriptions = subscriptions.filter(function (s) { return s.price > 0; });
    logger.info("AvailableSubsc", availableSubscriptions, subscriptions);
    return React.createElement("div", { style: {
            flex: 1,
            flexDirection: "column",
            padding: "10px"
        } },
        React.createElement("div", { style: {
                marginBottom: "10px"
            } },
            React.createElement(SectionTitle, null, "Pricing"),
            React.createElement(Box, { gap: "8px" },
                React.createElement(CFormCheck, { type: "radio", name: "month", id: "month", label: "Monthly", checked: period == "month", onChange: function (e) {
                        setPeriod(e.target.name);
                    } }),
                React.createElement(CFormCheck, { type: "radio", name: "quarter", id: "quarter", checked: period == "quarter", label: "Quarterly", onChange: function (e) {
                        setPeriod(e.target.name);
                    } }),
                React.createElement(CFormCheck, { type: "radio", name: "semi-annual", id: "semi-annual", checked: period == "semi-annual", label: "Semi-annualy", onChange: function (e) {
                        setPeriod(e.target.name);
                    } }),
                React.createElement(CFormCheck, { type: "radio", name: "annual", id: "annual", checked: period == "annual", label: "Annualy", onChange: function (e) {
                        setPeriod(e.target.name);
                    } }))),
        React.createElement(TariffPlansContainer, null,
            React.createElement(SectionTitle, null, "Current Plan"),
            React.createElement(CurrentTariffPlanContainer, null,
                React.createElement(HoriontalContainer, { style: {
                        alignItems: "center",
                        flex: 1
                    } },
                    React.createElement(VerticalContainer, null,
                        React.createElement(HoriontalContainer, null,
                            React.createElement(TariffPlanName, null, company.subscription.name),
                            "\u00A0 \u00A0")),
                    React.createElement(HoriontalContainer, { style: { flex: 1, justifyContent: "flex-end", alignItems: "center" } },
                        React.createElement(TariffPlanPrice, null,
                            React.createElement(Currency, { currency: company.currency }),
                            currentPrice,
                            "/month")))),
            React.createElement(SectionTitle, null, "Other Plans"),
            availableSubscriptions.map(function (subscription) {
                var _a, _b;
                var cp = ((_a = company === null || company === void 0 ? void 0 : company.subscription) === null || _a === void 0 ? void 0 : _a.price) || 0;
                var cpv = PeriodValueMap[(_b = company === null || company === void 0 ? void 0 : company.subscription) === null || _b === void 0 ? void 0 : _b.period] || 0;
                var pv = PeriodValueMap[period] || 0;
                var disabled = cpv == pv;
                if (!disabled) {
                    disabled = (subscription.price < cp) || (pv < cpv);
                }
                return React.createElement(TariffPlan, { disabled: disabled, key: subscription.id, plan: subscription, discount: discount, onSelect: function () {
                        setSub(subscription);
                    } });
            })),
        (sub != null) && React.createElement(Modal, { visible: true, onClose: function () { } },
            React.createElement(SubscriptionPayment, { onSuccess: function () {
                    setSub(null);
                    updateUserSubscription();
                }, subscriptionId: sub.id, period: period, onClose: function () {
                    setSub(null);
                    updateUserSubscription();
                } })));
});
var TariffPlan = React.memo(function (props) {
    var plan = props.plan, onSelect = props.onSelect, discount = props.discount, disabled = props.disabled;
    var company = userStore(function (state) { return state.company; });
    var price = Math.ceil(plan.price - (plan.price * (parseInt(discount.toString()) / 100)));
    return React.createElement(TariffPlanContainer, null,
        React.createElement(HoriontalContainer, { style: {
                alignItems: "center",
                flex: 1
            } },
            React.createElement(VerticalContainer, null,
                React.createElement(TariffPlanName, null, plan.name)),
            React.createElement(HoriontalContainer, { style: { flex: 1, justifyContent: "flex-end", alignItems: "center" } },
                React.createElement(TariffPlanPrice, null,
                    React.createElement(Currency, { currency: company.currency }),
                    price,
                    "/month"),
                React.createElement(CButton, { onClick: onSelect, disabled: disabled }, "Select"))));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
